<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex xs12 lg6 class="text-left">
                <span class="heading">Top Common Snakes</span>
              </v-flex>
              <v-flex
                xs12
                lg6
                class="text-end"
                v-if="topcommonsnakes && topcommonsnakes.length > 0"
              >
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="getExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel<v-icon color="black"
                      >mdi-microsoft-excel</v-icon
                    ></span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <span
              style="color: red; font-family: poppinsregular; font-size: 13px"
              >Please select the main criterias for search.
            </span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs10 sm8 md5 lg3>
                <v-layout wrap>
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="alldata"
                  label="All"
                  @change="checkUpdate()"
                  class="small-checkbox no-space"
                ></v-checkbox>
              </v-flex>
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="timeperiod"
                  label="Time Period"
                  :disabled="alldata"
                  class="small-checkbox no-space"
                ></v-checkbox>
              </v-flex>
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="bigsnakes"
                  label="District
"
                  :disabled="alldata"
                  class="small-checkbox no-space"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
            </v-layout>
            <v-layout wrap justify-start pb-2>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm3 md3 lg3 class="subheading" v-if="timeperiod">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span>From Date</span>
                        <v-text-field
                          v-model="fromdate"
                          label="Select"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-lg-2
                    pl-sm-2
                    pl-md-2
                    class="subheading"
                    v-if="timeperiod"
                  >
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span>To Date</span>
                        <v-text-field
                          v-model="todate"
                          label="Select"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        :active-picker.sync="activePickerto"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveto"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    lg3
                    sm3
                    md3
                    v-if="bigsnakes"
                    class="subheading"
                    :pl-lg-2="timeperiod ? true : false"
                    :pl-md-2="timeperiod ? true : false"
                    :pl-sm-2="timeperiod ? true : false"
                  >
                    <span>Select District</span>
                    <v-select
                      :items="districts"
                      v-model="bigsnakeselect"
                      outlined
                      dense
                      hide-details="true"
                      :disabled="alldata"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  justify-start
                  v-if="timeperiod || bigsnakes || allsnakes || users"
                  pt-4
                >
                  <v-flex xs12 md2 sm2 lg1>
                    <v-btn
                      color="success"
                      depressed
                      @click="getData()"
                      :disabled="alldata"
                    >
                      <span>Submit</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 md2 sm2 lg1>
                    <v-btn
                      color="warning"
                      depressed
                      @click="reset()"
                      :disabled="alldata"
                    >
                      <span>Reset</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex
                xs12
                pt-2
                v-if="topcommonsnakes && topcommonsnakes.length > 0"
              >
                <!-- <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      outlined
                      dense
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="topcommonsnakes"
                    :search="search"
                  ></v-data-table>
                </v-card> -->
                <v-card>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tableheading">Snake Name</th>
                          <th class="text-left tableheading">Venom Type</th>
                          <!-- <th class="text-left tableheading">Family</th> -->
                          <th class="text-left tableheading">
                            Scientific Name
                          </th>
                          <th class="text-left tableheading">Count</th>
                        </tr>
                      </thead>
                      <tbody class="tablesubheading">
                        <tr
                          v-for="(item, index) in topcommonsnakes"
                          :key="index"
                        >
                          <td>
                            <span
                              v-if="item && item.snakeName"
                              >{{ item.snakeName }}</span
                            >
                            <span v-else>Unknown</span>
                          </td>
                          <td>
                            <span
                              v-if="item && item.venomType"
                              >{{ item.venomType }}</span
                            >
                            <span v-else>Unknown</span>
                          </td>
                          <!-- <td>
                            <span
                              v-if="item && item.snake && item.snake.family"
                              >{{ item.snake.family }}</span
                            >
                            <span v-else>Unknown</span>
                          </td> -->
                          <td>
                            <span
                              v-if="
                                item && item.scientificName
                              "
                              >{{ item.scientificName }}</span
                            >
                            <span v-else>Unknown</span>
                          </td>
                          <td>
                            <span v-if="item && item.rescueCount">{{
                              item.rescueCount
                            }}</span>
                            <span v-else>Unknown</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 pb-4 v-if="topcommonsnakes && topcommonsnakes.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              :total-visible="7"
              circle
              color="#FF6907"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      justify-start
      v-if="topcommonsnakes && topcommonsnakes.length === 0"
    >
      <v-flex xs12 class="text-center" pl-5>
        <span class="heading">No Data Found!</span>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      pages: 0,
      count: 20,
      currentPage: 1,
      Pagelength: 0,
      datalist: [],
      topcommonsnakes: [],
      json_data: [],
      json_fields: {
        Snakename: "snake.name",
        Count: "count",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      search: "",
      headers: [
        {
          text: "Snake name",
          align: "start",
          sortable: false,
          value: "snake.name",
        },
        { text: "Count", value: "count" },
      ],
      districts: [
        "All",
        "Palakkad",
        "Alappuzha",
        "Malappuram",
        "Kannur",
        "Wayanadu",
        "Kozhikode",
        "Thrissur",
        "Thiruvananthapuram",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Kollam",
        "Pathanamthitta",
        "Kasaragod",
      ],
      alldata: true,
      timeperiod: false,
      bigsnakes: false,
      allsnakes: false,
      users: false,
      activePicker: null,
      fromdate: null,
      menu: false,
      activePickerto: null,
      todate: null,
      menu2: false,
      bigsnakeselect: "",
      allsnakelist: "",
      selecteduser: "",
      bigsnakestypes: [],
      situationlist: [
        "Inside the house",
        "Just outside the building",
        "Natural",
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePickerto = "YEAR"));
    },
    currentPage() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/rescued/snakewise/count/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          to: this.todate,
          from: this.fromdate,
          district: this.bigsnakeselect,
          count:this.count,
          page:this.currentPage,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.topcommonsnakes = response.data.data;
              this.Pagelength = Math.ceil(response.data.count / this.count);
              this.json_data = response.data.data.topsnakesyear;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    checkUpdate() {
      if (this.alldata == true) {
        this.timeperiod = false;
        this.bigsnakes = false;
        this.allsnakes = false;
        this.users = false;
        this.fromdate = null;
        this.todate = null;
        this.bigsnakeselect = "";
        this.allsnakelist = [];
        this.selecteduser = "";
        this.getData();
      }
    },
    reset() {
      // Reset all checkboxes
      this.timeperiod = false;
      this.bigsnakes = false;
      this.allsnakes = false;
      this.users = false;

      // Reset date pickers
      this.fromdate = null;
      this.todate = null;

      // Reset big snake selection
      this.bigsnakeselect = [];

      // Reset all snake list
      this.allsnakelist = [];

      // Reset user selection
      this.selecteduser = "";
      this.getData();
      // You can also trigger any other actions if needed
      // this.msg = "Fields have been reset";
      // this.showSnackBar = true;
    },
    save(fromdate) {
      this.$refs.menu.save(fromdate);
    },
    saveto(todate) {
      this.$refs.menu2.save(todate);
    },
    getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/rescued/district/snakewise/count/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          district: this.bigsnakeselect,
          from:this.fromdate,
          to: this.todate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Topsnakes.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
}
.tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 10px;
}
</style>
  